@import 'styles/typography';
@import 'styles/breakpoints';

// SET COLOUR VARIABLES
.button {
  --inactive: var(--ink-lighter);
}

.button--primary {
  --accent-color: var(--teal-light);
  --border-color: var(--teal-lighter);
  --hover-fill-color: var(--teal-lighter);
  --hover-text-color: var(--teal-base);
  --active-color: var(--teal-lightest);
  --filled-active-text-color: var(--teal-base);
  --filled-active-border-color: var(--active-color);
  --filled-text-color: var(--teal-darkest);
  --outline-accent-color: var(--accent-color);
  --outline-hover-background-color: transparent;
  --outline-hover-color: var(--hover-color);
  --button-icon: var(--accent-color);
}

.button--secondary {
  --accent-color: var(--teal-darkest);
  --border-color: var(--teal-darkest);
  --hover-fill-color: var(--ink-base);
  --hover-text-color: var(--teal-light);
  --active-color: var(--ink-dark);
  --filled-active-text-color: var(--teal-base);
  --filled-active-border-color: var(--active-color);
  --filled-text-color: var(--sky-white);
  // --outline-accent-color: var(--accent-color);
  // --outline-hover-background-color: transparent;
  // --outline-hover-color: var(--hover-color);
  --button-icon: var(--accent-color);
}

.button--overlay {
  --accent-color: rgba(255, 255, 255, 0.2);
  --border-color: var(--sky-white);
  --hover-color: rgba(255, 255, 255, 0.4);
  --active-color: rgba(255, 255, 255, 0.1);
  --filled-active-text-color: var(--border-color);
  --filled-active-border-color: var(--border-color);
  --filled-text-color: var(--border-color);
  --outline-accent-color: var(--border-color);
  --outline-hover-background-color: var(--active-color);
  --outline-hover-color: var(--border-color);
  --button-icon: var(--border-color);
}

.button {
  @include button-regular;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: var(--border-radius-xs);
  padding: 11px 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
  touch-action: manipulation;
  filter: none;
  position: relative;

  &:hover,
  &:focus {
    transition: border-color 0.2s ease-out, background-color 0.2s ease-out,
      color 0.2s ease-out;
    // svg fill won't animate
  }

  &:active {
    transition: none;
  }

  &:disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &--small {
    @include button-small;
    padding-block: 8px;
  }

  &--fitContent {
    width: fit-content;
  }

  &--left {
    svg {
      margin-right: 8px;
    }
  }

  &--right {
    svg {
      margin-left: 8px;
    }
  }

  // FILLED STYLES
  &--filled {
    border-color: var(--border-color);
    background-color: var(--accent-color);
    color: var(--filled-text-color);
    --button-icon: var(--filled-text-color);

    &:visited {
      color: var(--filled-text-color);
    }

    &:hover,
    &:focus {
      background-color: var(--hover-fill-color);
      border-color: var(--hover-fill-color);
      color: var(--hover-text-color);
      --button-icon: var(--hover-text-color);
    }

    &:active {
      background-color: var(--active-color);
      border-color: var(--filled-active-border-color);
      color: var(--filled-active-text-color);
      --button-icon: var(--filled-active-text-color);
    }

    &:disabled,
    &[aria-disabled='true'] {
      background-color: var(--inactive);
      border-color: var(--inactive);
      color: var(--sky-white);
      --button-icon: var(--sky-white);
    }
  }

  // OUTLINED STYLES
  &--outline {
    background-color: transparent;
    color: var(--outline-accent-color);
    border-color: var(--outline-accent-color);

    &:visited {
      color: var(--outline-accent-color);
    }

    &:hover,
    &:focus {
      color: var(--outline-hover-color);
      border-color: var(--outline-hover-color);
      background-color: var(--outline-hover-background-color);
      --button-icon: var(--outline-hover-color);
    }

    &:active {
      color: var(--outline-accent-color);
      border-color: var(--active-color);
      background-color: transparent;
      --button-icon: var(--outline-accent-color);
    }

    &:disabled,
    &[aria-disabled='true'] {
      border-color: var(--inactive);
      color: var(--inactive);
      --button-icon: var(--inactive);
    }
  }

  // TEXT ONLY STYLES
  &--text {
    min-height: 40px; // more accessible touch target height
    background-color: transparent;
    border-color: transparent;
    color: var(--outline-accent-color);

    &:visited {
      color: var(--outline-accent-color);
    }

    &:hover,
    &:focus {
      color: var(--hover-color);
      --button-icon: var(--hover-color);
    }

    &:active {
      color: var(--accent-color);
      border-color: var(--active-color);
      --button-icon: var(--accent-color);
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: var(--inactive);
      border-color: transparent;
      --button-icon: var(--inactive);
    }
  }
}

// TEXT BUTTON OVERLAY STYLES
.button--overlay.button--text {
  border: 1px solid transparent;

  &:hover,
  &:focus {
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
    transition: 0.2s ease-out;
    * {
      // controls icon
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
      transition: 0.3s ease-out;
    }
  }

  &:active {
    color: rgba(255, 255, 255, 0.7);
    --button-icon: rgba(255, 255, 255, 0.7);
    filter: none;
    * {
      filter: none;
    }
  }
}

.button--unstyled {
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    transition: none;
  }
}

.button--orb {
  background-color: transparent;
  border-color: transparent;
  background: radial-gradient(
    75.81% 75.81% at 50% 50%,
    #ffffff 0%,
    #abfef9 46.87%,
    rgba(171, 254, 249, 0) 100%,
    rgba(206, 255, 252, 0) 100%
  );
  box-shadow: (0 0 30px #dbfffd);
  width: 44px;
  height: 44px;
  border-radius: 50%;

  &:hover {
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: (0 0 30px #dbfffd);
    box-shadow: (0 0 30px #dbfffd);
  }
  50% {
    -moz-box-shadow: (0 0 30px 10px #dbfffd);
    box-shadow: (0 0 30px 10px #dbfffd);
  }
  100% {
    -moz-box-shadow: (0 0 30px #dbfffd);
    box-shadow: (0 0 30px #dbfffd);
  }
}
