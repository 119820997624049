@import 'styles/_breakpoints.scss';

.footer {
  display: flex;
  gap: 32px;
  padding: 20px calc(6vw / 2);
  z-index: 0;
  align-items: center;
  list-style: none;
  margin: 0;
  justify-content: flex-end;

  &__fixed {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.link {
  cursor: pointer;
  display: grid;
  align-items: center;

  &:hover {
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
  }
}

@media screen and (max-width: $tablet) {
  .footer {
    display: none;
  }
}
