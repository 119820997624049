@import './../../styles/breakpoints';
@import './../../styles/typography';

.typography {
  display: block;

  &--heading-1 {
    @include heading-1;
  }

  &--heading-2 {
    @include heading-2;
  }

  &--heading-3 {
    @include heading-3;
  }

  &--sub-heading-1 {
    @include sub-heading-1;
  }

  &--sub-heading-1-mobile {
    @include sub-heading-1-mobile;
  }

  &--sub-heading-2 {
    @include sub-heading-2;
  }

  &--sub-heading-2-mobile {
    @include sub-heading-2-mobile;
  }

  &--button-regular {
    @include button-regular;
  }

  &--button-regular-underline {
    @include button-regular-underline;
  }

  &--body-regular {
    @include body-regular;
  }

  &--body-regular-book {
    @include body-regular-book;
  }

  &--body-small {
    @include body-small;
  }

  &--body-small-book {
    @include body-small-book;
  }

  &--body-xsmall {
    @include body-xsmall;
  }

  &--body-xsmall-book {
    @include body-xsmall-book;
  }

  &--button-small {
    @include button-small;
  }

  &--button-small-underline {
    @include button-small-underline;
  }

  &--sky-dark {
    color: var(--sky-dark);
  }

  &--sky-base {
    color: var(--sky-base);
  }

  &--teal-light {
    color: var(--teal-light);
  }

  &--sky-white {
    color: var(--sky-white);
  }

  &--ink-lighter {
    color: var(--ink-lighter);
  }

  &--error {
    color: var(--red-light);
  }

  &--ink-darkest {
    color: var(--ink-darkest);
  }

  &--noBottomMargin {
    margin-bottom: 0px;
  }

  &--noTopMargin {
    margin-top: 0;
  }

  &--noMargins {
    margin: 0;
  }

  &--max-width {
    max-width: 500px;
  }
}
