@use 'sass:math';
@import 'styles/breakpoints';
@import 'styles/zindex';
@import 'styles/_typography.scss';

$hamburger-bar-size: 28px;
$hamburger-color: var(--sky-white);
$hamburger-thickness: 2px;
$root-two: math.sqrt(2);
$hamburger-easing: cubic-bezier(0.645, 0.045, 0.355, 1);
$hamburger-easing-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$hamburger-easing-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$hamburger-animation-speed: 1;

.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px calc(6vw / 2);
  align-items: center;
  position: relative;
  z-index: $z-nav;

  &__logo {
    z-index: $z-nav;
  }

  &--semiOpaque {
    background-color: rgba(0, 0, 0, 0.31);
  }

  .loginButton {
    padding: 0 45px;
  }

  button {
    font-size: 14px;
    // padding: 0 45px;
  }

  .link {
    display: grid;
    align-items: center;

    &:hover {
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
    }
  }
}

.hamburger {
  display: none;
}

.menuLinks {
  display: flex;
  align-items: center;
  gap: 32px;
  list-style: none;
  margin: 0;
  padding: 0;

  .hideOnDesktop {
    display: none;
  }

  & > li > p {
    font-size: 14px;
    line-height: 1;
  }
}

.wallet {
  @include button-small;

  &:hover {
    text-stroke: 1px white;
    -webkit-text-stroke: 1px white;
  }
}

.info {
  height: fit-content;
  background-color: var(--ink-darkest);
  border: solid 1px var(--ink-lighter);
  border-radius: var(--border-radius-small);
  opacity: 0;
  visibility: hidden;
  transition: var(--fade-transition);
  position: absolute;
  right: calc(6vw / 2);
  top: var(--navbar-height);
  z-index: $z-nav;

  & > dl {
    margin: 0;
  }

  dt {
    font-family: var(--copperplate);
    font-size: 0.625rem;
    line-height: 14px;
  }

  dd {
    @include button-regular;
    margin-bottom: 4px;
  }

  & > dl > div {
    display: grid;
    height: 100%;
    grid-template-columns: 30px auto;
    grid-area: 'left right';
    column-gap: 8px;
    padding: 13px 60px 13px 10px;
    align-items: center;
    border-bottom: solid 1px var(--ink-lighter);

    &:last-child {
      border-bottom: none;
    }

    & > div:last-of-type {
      grid-column: 2/3;
      display: flex;
      flex-direction: column-reverse;
    }

    & > img {
      justify-self: end;
    }
  }

  &--hovered {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: $tablet) {
  .menuLinks {
    .hideOnDesktop {
      display: unset;
    }
  }

  .navbar {
    padding: 16px;
    isolation: isolate;
    z-index: 100;

    &__logo,
    &__logo img {
      width: 138px;
      height: 40px;
    }

    .loginButton {
      padding: unset;
    }
  }

  .info {
    &--hovered {
      display: none;
    }
  }

  .hamburger {
    z-index: 106;
    height: 44px;
    width: 44px;
    padding: 8px;
    display: block;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &__inner {
      position: relative;
      width: 100%;
      height: 2px;

      &__bar {
        display: block;
        position: absolute;
        width: 100%;
        height: $hamburger-thickness;
        background-color: $hamburger-color;

        &:nth-child(1) {
          top: -10px;
        }

        &:nth-child(3) {
          top: 10px;
        }
      }
    }
  }

  .menuLinks {
    position: absolute;
    display: flex;
    flex-direction: column;
    opacity: 0;
    top: -100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 84px 0 0;
    background-color: var(--ink-darkest);
    z-index: $z-nav-menu;
    opacity: 0;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    overflow-y: scroll;

    @media (prefers-reduced-motion: no-preference) {
      transition: opacity 1s ease, transform 1s ease;
    }

    &--is-open {
      position: fixed;

      opacity: 1;
      transform: translate3d(0, 100vh, 0);

      li:first-child {
        padding-top: 32px;
      }

      li:last-child {
        padding-bottom: 32px;
      }

      button {
        width: min(258px, 80vw);
        // padding: unset;
      }
    }
  }
}

// HAMBURGER ANIMATION

.hamburger {
  $offset-y: math.div(math.div($hamburger-bar-size, 2), $root-two);
  $offset-x: math.div($hamburger-bar-size, 2) - $offset-y;

  > .hamburger__inner {
    &::before,
    &::after {
      background-color: $hamburger-color;
      content: '';
      display: block;
      height: $hamburger-thickness;
      position: absolute;
      width: 100%;

      opacity: 0;
      transform-origin: 0 50%;
      transition: opacity math.div(0.4s, $hamburger-animation-speed)
          $hamburger-easing,
        transform math.div(0.4s, $hamburger-animation-speed) $hamburger-easing;
    }

    &::before {
      transform: rotate(45deg) translate3d(-$offset-x, -$offset-y, 0) scaleX(0);
    }

    &::after {
      transform: rotate(-45deg) translate3d(-$offset-x, $offset-y, 0) scaleX(0);
      transition-delay: math.div(0.1s, $hamburger-animation-speed);
    }
  }

  span.hamburger__inner__bar {
    transition: transform math.div(0.4s, $hamburger-animation-speed)
        $hamburger-easing,
      opacity math.div(0.4s, $hamburger-animation-speed) $hamburger-easing,
      width math.div(0.4s, $hamburger-animation-speed) $hamburger-easing;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition-delay: math.div(
          0.3 + ($i * 0.03s),
          $hamburger-animation-speed
        );
      }
    }
  }

  &--is-open {
    > .hamburger__inner {
      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        transform: rotate(45deg) translate3d(-$offset-x, -$offset-y, 0);
        transition-delay: math.div(0.4s, $hamburger-animation-speed);
      }

      &::after {
        transform: rotate(-45deg) translate3d(-$offset-x, $offset-y, 0);
        transition-delay: math.div(0.5s, $hamburger-animation-speed);
      }
    }

    span.hamburger__inner__bar {
      opacity: 0;
      transform: translate3d(-10px, 0, 0);
      width: 50%;

      &:nth-child(1) {
        transition-delay: math.div(0s, $hamburger-animation-speed);
      }

      &:nth-child(2) {
        transition-delay: math.div(0.2s, $hamburger-animation-speed);
      }

      &:nth-child(3) {
        transition-delay: math.div(0.1s, $hamburger-animation-speed);
      }
    }
  }
}
