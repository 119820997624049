@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  // width: 50px;
  // height: 50px;
  // border: 10px solid #f3f3f3; /* Light grey */
  // border-top: 10px solid #383636; /* Blue */
  // border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100px;
}
