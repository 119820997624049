@import './breakpoints';

@font-face {
  font-family: 'arkhip';
  src: url('/fonts/arkhip.woff2') format('woff2'),
    url('/fonts/arkhip.woff') format('woff');
  font-weight: var(--regular-weight);
  font-style: normal;
  font-display: swap;
}

@mixin heading-1 {
  font-size: 3.125rem;
  line-height: 1.05;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);
  text-transform: uppercase;

  @media (max-width: $md) {
    font-size: 2.25rem;
  }
}

@mixin heading-2 {
  font-size: 2.5rem;
  line-height: 1.05;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);
  text-transform: uppercase;

  @media (max-width: $sm) {
    font-size: 2.25rem;
    line-height: 1;
  }
}

@mixin heading-3 {
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);
  text-transform: uppercase;

  @media (max-width: $sm) {
    font-size: 2.25rem;
    line-height: 1;
  }
}

@mixin sub-heading-1 {
  font-size: 1.5rem;
  line-height: 1.33;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);
  text-transform: uppercase;

  @media (max-width: $md) {
    font-size: 1.25rem;
  }
}

@mixin sub-heading-1-mobile {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: var(--copperplate);
  text-transform: uppercase;
}

@mixin sub-heading-2 {
  font-size: 1.125rem;
  line-height: 1.78;
  font-family: var(--arkhip);
  font-weight: var(--regular-weight);
  text-transform: uppercase;

  @media (max-width: $md) {
    font-size: 0.875rem;
  }
}

@mixin sub-heading-2-mobile {
  font-size: 0.875rem;
  line-height: 1.29;
  font-family: var(--arkhip);
  font-weight: var(--regular-weight);
  text-transform: uppercase;
}

@mixin button-regular {
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);
  text-transform: uppercase;
}

@mixin button-regular-underline {
  @include button-regular;
  text-decoration: underline;
}

@mixin body-regular {
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--space-grotesk);
  font-weight: var(--big-weight);
}

@mixin body-regular-book {
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--space-grotesk);
  font-weight: var(--small-weight);
}

@mixin body-small {
  font-size: 0.875rem;
  line-height: 1.43;
  font-family: var(--space-grotesk);
  font-weight: var(--regular-weight);
}

@mixin body-small-book {
  font-size: 0.875rem;
  line-height: 1.43;
  font-family: var(--space-grotesk);
  font-weight: var(--small-weight);
}

@mixin body-xsmall {
  font-size: 0.75rem;
  line-height: 1.33;
  font-family: var(--space-grotesk);
  font-weight: var(--regular-weight);
}

@mixin body-xsmall-book {
  font-size: 0.75rem;
  line-height: 1.33;
  font-family: var(--program);
  font-weight: var(--small-weight);
}

@mixin button-small {
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: 0.1em;
  font-family: var(--copperplate);
  font-weight: var(--regular-weight);

  // @media (max-width: $md) {
  //   // font-size: 0.9375rem;
  // }
}

@mixin button-small-underline {
  @include button-small;
  text-decoration: underline;
}
