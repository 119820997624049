@import 'styles/breakpoints';
@import 'styles/zindex';

.modal {
  &[data-reach-dialog-overlay] {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    z-index: $z-modal;
  }

  &__container {
    display: grid;
    grid-template-rows: min-content 1fr;
    gap: 32px;
    background-color: var(--ink-dark);
    border-radius: var(--border-radius-large);
    width: min(55vw, 740px);

    .close {
      width: fit-content;
      background-color: transparent;
      border: none;
      justify-self: end;
      --button-icon: white;

      .noClose {
        display: none;
      }

      &:hover {
        & > svg {
          filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
        }
      }
    }
  }

  &__content {
    display: grid;
    justify-items: center;
    text-align: center;
    gap: 32px;
    margin-bottom: 100px;
    padding-inline: 56px;

    & > button {
      white-space: normal;
    }
  }
}

.open {
  animation: fade 0.2s ease-in-out;

  &__container {
    animation: scale 0.2s ease-in-out;
  }
}

@media (max-width: $md) {
  .modal {
    &__container {
      width: 70vw;
    }
  }
}

@media (max-width: $tablet) {
  .modal {
    &__content {
      padding: 0;
    }
  }
}

@media (max-width: $sm) {
  .modal {
    &__container {
      width: 98vw;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale {
  from {
    transform: scale(0.5);
  }
  to {
    transform: none;
  }
}
