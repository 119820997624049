@import '../../styles/typography.scss';

.link {
  @include button-regular;
  font-size: 14px;

  &:hover {
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
  }

  &:active {
    opacity: 0.7;
  }

  &--active {
    font-weight: bold;
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
    color: #787878 !important;
  }
}
